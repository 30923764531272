/* Login.css */
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}
  
.login-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 40%; /*Set a width for the form
  margin-bottom: 20px; /*Add margin at the bottom of the form*/
}
