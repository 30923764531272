.child-care-registration-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }
  
  .child-care-registration-form {
    max-width: 1000px;
    width: 100%;
  }
  
  /* Optional styling for form sections */
  h2 {
    margin-top: 20px;
  }
  
  /* Optional styling for spacing between form groups */
  .mx-md-4,
  .mx-2 {
    margin-top: 10px;
  }
  
  /* Optional styling for error messages */
  .invalid-feedback {
    display: block;
    color: #dc3545;
    margin-top: 5px;
  }
  
  /* Optional styling for the "Add Another Child" button */
  .mx-4 {
    margin-top: 20px;
  }
  
  /* Optional styling for the Submit button */
  .mx-md-4[type='submit'] {
    margin-top: 20px;
  }
  
  /* Optional styling for the comments textarea */
  textarea {
    resize: none;
  }
  