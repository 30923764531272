/* SignUp.css */
.signup-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
}

.signup-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* width: 300px; Set a width for the form */
  margin-bottom: 20px; /*Add margin at the bottom of the form*/
}
  
.spacer {
    margin-bottom: 20px; /* Adjust the margin as needed */
}  
  