/* PasswordReset.css */
.password-reset-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
  }
  
  .password-reset-form {
    display: flex;
    flex-direction: column;
    /* gap: 10px; */
  }

  .spacer {
    margin-bottom: 20px; /* Adjust the margin as needed */
}    